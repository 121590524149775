<template>
	<div v-if="computedValue === `ko`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="price_content price_content-desktop">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining 패키지
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining 입력
						</h3>
						<div class="border"></div>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}원
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
									</h3>
									<h3>최소 입력 단위는 2mining 입니다.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" focus />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>합계</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>원</label
									>
								</div>
								<button @click="checkAmount(form.mining)">구매하기</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>구매 가능한 ppt 템플릿</h3>
						<p>BI-Mining 로그인 후 구매가 가능합니다.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateList" />
					<p class="price-content_slide">
						새로운 PPT 디자인은 꾸준히 업데이트 될 예정입니다.
					</p>
					<button @click="gotoservice">서비스 바로가기</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining 이용 안내</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>- mining 구매에 대해서는 부가가치세가 부과되지 않습니다.</li>
							<li>
								- mining 구매 시 이용약관 동의가 필요합니다.
								<a href="/terms" target="_blank"
									><span>&lt;이용약관&gt;</span></a
								>
							</li>
							<li>
								- mining 구매는 최소 2 mining (1,000원)부터 구매가 가능합니다.
							</li>
							<li>
								- 충전한 마이닝을 사용하여
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								템플릿을 구매 할수 있습니다.
							</li>
							<li>- 지급된 쿠폰은 재사용이 불가능합니다.</li>
						</ul>
						<p>환불정책 바로가기 >></p>
					</div>
				</div>
			</div>
		</div>
		<!-- tablet -->
		<div class="price_content-tablet">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining 패키지
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining 입력
						</h3>
						<div class="border"></div>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}원
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
									</h3>
									<h3>최소 입력 단위는 2mining 입니다.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>합계</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>원</label
									>
								</div>
								<button @click="checkAmount(form.mining)">구매하기</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>구매 가능한 ppt 템플릿</h3>
						<p>BI-Mining 로그인 후 구매가 가능합니다.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateListTablet" />
					<p class="price-content_slide">
						새로운 PPT 디자인은 꾸준히 업데이트 될 예정입니다.
					</p>
					<button @click="gotoservice">서비스 바로가기</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining 이용 안내</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>- mining 구매에 대해서는 부가가치세가 부과되지 않습니다.</li>
							<li>
								- mining 구매 시 이용약관 동의가 필요합니다.
								<label>&lt;이용약관&gt;</label>
							</li>
							<li>
								- mining 구매는 최소 2 mining (1,000원)부터 구매가 가능합니다.
							</li>
							<li>
								- 충전한 마이닝을 사용하여
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								템플릿을 구매 할수 있습니다.
							</li>
							<li>- 지급된 쿠폰은 재사용이 불가능합니다.</li>
						</ul>
						<p>환불정책 바로가기 >></p>
					</div>
				</div>
			</div>
		</div>
		<!-- mobile -->
		<div class="price_content-mobile">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining 패키지
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining 입력
						</h3>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}원
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Mining을 구매해 더 멋진 나만의 프레젠테이션을 만들어 보세요.
									</h3>
									<h3>최소 입력 단위는 2mining 입니다.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>합계</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>원</label
									>
								</div>
								<button @click="checkAmount(form.mining)">구매하기</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>구매 가능한 ppt 템플릿</h3>
						<p>BI-Mining 로그인 후 구매가 가능합니다.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateListMobile" />
					<p class="price-content_slide">
						새로운 PPT 디자인은 꾸준히 업데이트 될 예정입니다.
					</p>
					<button @click="gotoservice">서비스 바로가기</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining 이용 안내</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>- mining 구매에 대해서는 부가가치세가 부과되지 않습니다.</li>
							<li>
								- mining 구매 시 이용약관 동의가 필요합니다.
								<label>&lt;이용약관&gt;</label>
							</li>
							<li>
								- mining 구매는 최소 2 mining (1,000원)부터 구매가 가능합니다.
							</li>
							<li>
								- 충전한 마이닝을 사용하여
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								템플릿을 구매 할수 있습니다.
							</li>
							<li>- 지급된 쿠폰은 재사용이 불가능합니다.</li>
						</ul>
						<p>환불정책 바로가기 >></p>
					</div>
				</div>
			</div>
		</div>

		<Footer />
	</div>
	<div v-else-if="computedValue === `en`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="price_content price_content-desktop">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining package
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining input
						</h3>
						<div class="border"></div>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Purchase mining to create your own stunning presentations.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}KRW
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Purchase mining to create your own stunning presentations.
									</h3>
									<h3>The minimum purchase unit is 2 mining.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" focus />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>Total</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>KRW</label
									>
								</div>
								<button @click="checkAmount(form.mining)">Purchase Now</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>Available PPT template</h3>
						<p>Can be purchased after logging into BI Mining.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateList" />
					<p class="price-content_slide">
						New PPT designs will be regularly updated.
					</p>
					<button @click="gotoservice">Go to Purchase</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining Usage Guide</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>
								- Value-added tax (VAT) is not charged on mining purchases.
							</li>
							<li>
								- Agreement to the terms and conditions is required for mining
								purchases.
								<a href="/terms" target="_blank"
									><span>&lt;Terms and Conditions&gt;</span></a
								>
							</li>
							<li>
								- Mining purchases can be made starting from a minimum of 2
								mining units (1,000 KRW).
							</li>
							<li>
								- Charged mining can be used to purchase
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								templates.
							</li>
							<li>- Issued coupons cannot be reused.</li>
						</ul>
						<p>Go to refund policy >></p>
					</div>
				</div>
			</div>
		</div>
		<!-- tablet -->
		<div class="price_content-tablet">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining package
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining input
						</h3>
						<div class="border"></div>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Purchase mining to create your own stunning presentations.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}KRW
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Purchase mining to create your own stunning presentations.
									</h3>
									<h3>The minimum purchase unit is 2 mining.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>Total</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>KRW</label
									>
								</div>
								<button @click="checkAmount(form.mining)">Purchase Now</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>Available PPT template</h3>
						<p>Can be purchased after logging into BI Mining.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateListTablet" />
					<p class="price-content_slide">
						New PPT designs will be regularly updated.
					</p>
					<button @click="gotoservice">Go to Purchase</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining Usage Guide</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>
								- Value-added tax (VAT) is not charged on mining purchases.
							</li>
							<li>
								- Agreement to the terms and conditions is required for mining
								purchases.
								<label>&lt;Terms and Conditions&gt;</label>
							</li>
							<li>
								- Mining purchases can be made starting from a minimum of 2
								mining units (1,000 KRW).
							</li>
							<li>
								- Charged mining can be used to purchase
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								templates.
							</li>
							<li>- Issued coupons cannot be reused.</li>
						</ul>
						<p>Go to refund policy >></p>
					</div>
				</div>
			</div>
		</div>
		<!-- mobile -->
		<div class="price_content-mobile">
			<div class="price_Header price_Header-mining">
				<img :src="pricing_BLogo" />
				<div class="Header_Name">Mining Payment</div>
			</div>

			<div class="price_Main price_Main-bimining">
				<div class="price_content-box price_content-pay">
					<div class="price_content-header">
						<h3
							@click="priceMenu = 0"
							:class="[priceMenu === 0 ? 'active' : '']"
						>
							mining package
						</h3>
						<h3
							@click="priceMenu = 1"
							:class="[priceMenu === 1 ? 'active' : '']"
						>
							mining input
						</h3>
					</div>
					<div class="price-content_select">
						<div v-if="priceMenu === 0" class="price-content-package">
							<h3>
								Purchase mining to create your own stunning presentations.
							</h3>
							<ul>
								<li v-for="(data, index) in packages" :key="index">
									<div>
										<img :src="MiningIcon" />
										<label>{{ data.mining }} mining</label>
										<p v-if="data.mining === 30">BEST!</p>
									</div>
									<button @click="checkAmount(data.mining)">
										{{ data.amount }}KRW
									</button>
								</li>
							</ul>
						</div>
						<div v-else class="price-content-insert">
							<div class="price-content-insert_box">
								<div class="price-content-insert_header">
									<h3>
										Purchase mining to create your own stunning presentations.
									</h3>
									<h3>The minimum purchase unit is 2 mining.</h3>
								</div>
								<div class="price-content-insert_input">
									<input type="number" v-model="form.mining" min="2" />
									<label>mining</label>
								</div>
								<div class="price-content-insert_value">
									<label>Total</label>
									<label
										><span>{{ form.mining * 500 }}</span
										>KRW</label
									>
								</div>
								<button @click="checkAmount(form.mining)">Purchase Now</button>
							</div>
						</div>
					</div>
				</div>
				<div class="price_content-box price-content_template">
					<div class="price_content-header">
						<h3>Available PPT template</h3>
						<p>Can be purchased after logging into BI Mining.</p>
					</div>
					<hr />
					<img class="price-content_template" :src="TemplateListMobile" />
					<p class="price-content_slide">
						New PPT designs will be regularly updated.
					</p>
					<button @click="gotoservice">Go to Purchase</button>
				</div>
				<div class="price_detail">
					<div class="price_detai-header">
						<h3>Mining Usage Guide</h3>
					</div>
					<div class="price_detail-box">
						<ul>
							<li>
								- Value-added tax (VAT) is not charged on mining purchases.
							</li>
							<li>
								- Agreement to the terms and conditions is required for mining
								purchases.
								<label>&lt;Terms and Conditions&gt;</label>
							</li>
							<li>
								- Mining purchases can be made starting from a minimum of 2
								mining units (1,000 KRW).
							</li>
							<li>
								- Charged mining can be used to purchase
								<a href="https://bi-mining.org/" target="_blank"
									><span>BI-Mining</span></a
								>
								templates.
							</li>
							<li>- Issued coupons cannot be reused.</li>
						</ul>
						<p>Go to refund policy >></p>
					</div>
				</div>
			</div>
		</div>

		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import pricing_BLogo from '@/assets/images/pricing/pricing_BLogo.svg';
import MiningIcon from '@/assets/images/pricing/MiningIcon.svg';
import TemplateList from '@/assets/images/pricing/TemplateList.svg';
import TemplateListTablet from '@/assets/images/pricing/TemplateListTablet.svg';
import TemplateListMobile from '@/assets/images/pricing/TemplateListMobile.svg';

export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			pricing_BLogo,
			MiningIcon,
			TemplateList,
			TemplateListTablet,
			TemplateListMobile,
			// menuKr: 'Bi-Mining 요금안내',
			menuEn: 'BI-Mining Price',
			pricing: '',
			popupView: false,
			priceMenu: 0,
			packages: [
				{ mining: 2, amount: '1,000' },
				{ mining: 3, amount: '1,500' },
				{ mining: 6, amount: '3,000' },
				{ mining: 10, amount: '5,000' },
				{ mining: 20, amount: '10,000' },
				{ mining: 30, amount: '15,000' },
			],
			form: {
				amount: 1000,
				mining: 2,
			},
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko' ? 'Bi-Mining 요금안내' : '';
		},
	},
	methods: {
		async openPaymentsPopup(mining) {
			const bodyWidth = window.screen.width;
			const bodyHeight = window.screen.height;
			const popupWidth = 1058;
			const popupHeight = 999;

			window.open(
				`/paymentspopup/mining?data=${encodeURIComponent(mining)}`,
				'_blank',
				`width=${popupWidth}, height=${popupHeight}, top=${
					bodyHeight / 2 - popupHeight / 2
				}, left=${bodyWidth / 2 - popupWidth / 2}`,
			);
		},

		gotoservice() {
			if (this.$store.getters.isLogin === true) {
				this.$router.push('/serviceBiMining');
			} else if (this.$store.getters.isLogin === false) {
				this.$router.push('/login');
			}
		},

		async checkAmount(mining) {
			this.form.amount = mining * 500;
			if (mining >= 2) {
				await this.openPaymentsPopup(mining);
			} else {
				return alert(
					'mining 구매는 최소 2 mining (1,000원)부터 구매가 가능합니다.',
				);
			}
		},
	},
};
</script>

<style></style>
